.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.loader-dot {
  width: 15px;
  height: 15px;
  margin: 0 5px;
  background-color: #007bff;
  border-radius: 50%;
  animation: loader-animation 1s infinite;
}

.loader-dot:nth-child(1) {
  animation-delay: 0s;
}

.loader-dot:nth-child(2) {
  animation-delay: 0.2s;
}

.loader-dot:nth-child(3) {
  animation-delay: 0.4s;
}

.loader-dot:nth-child(4) {
  animation-delay: 0.6s;
}

.loader-dot:nth-child(5) {
  animation-delay: 0.8s;
}

@keyframes loader-animation {
  0%,
  100% {
    opacity: 1;
    transform: translateY(0);
  }
  50% {
    opacity: 0.5;
    transform: translateY(-10px);
  }
}
