/* generated global stylesheet */
:root {
  --mui-palette-primary-main: #002c5f;
  --mui-palette-primary-light: #42a5f5;
  --mui-palette-primary-dark: #1565c0;
  --mui-palette-primary-contrastText: #fff;
  /* ...other variables */
}
@media (prefers-color-scheme: dark) {
  :root {
    --mui-palette-primary-main: #76b4e6;
    --mui-palette-primary-light: #42a5f5;
    --mui-palette-primary-dark: #1565c0;
    --mui-palette-primary-contrastText: #fff;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px !important;
  overflow: auto !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.ag-theme-quartz .ag-header-row-column,
.ag-theme-quartz .ag-paging-panel,
.ag-theme-quartz .ag-icon,
.ag-theme-quartz .ag-picker-field-wrapper {
  background-color: #002c5f;
  color: white !important;
  border-color: #fff;
}
/* .ag-theme-quartz .ag-picker-field-wrapper {
  color: black;
} */
.ag-theme-quartz,
.ag-theme-quartz-dark {
  height: calc(100vh - 220px) !important;
  /* height: 100%; */
  /* margin-top: 10px; */
}
.ag-theme-quartz .ag-popup,
.ag-theme-quartz-dark .ag-popup {
  position: absolute;
}

.ag-theme-quartz-dark .ag-header-row-column,
.ag-theme-quartz-dark .ag-paging-panel,
.ag-theme-quartz-dark .ag-center-cols-viewport,
.ag-theme-quartz-dark .ag-row,
.ag-theme-quartz-dark .ag-picker-field-wrapper {
  background-color: #4e4e4e;
}

.ag-header-row-column,
.ag-center-cols-container {
  font-size: 12px !important;
}
.cellColorAnalysis {
  background-color: #bddcff;
  border: 1px solid #6f8eb0 !important;
}
::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--mui-palette-primary-main);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  /* background: rgb(4, 44, 82); */
  background-color: #3498db;
}

.login-container {
  background-image: url(/public/hcar2.png) !important;
  width: 100% !important;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.css-ttn1xo {
  background-image: url(/public/background.jpeg) !important;
  width: 100% !important;
  height: calc(100vh - 55px);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}
.AppRelative {
  width: 100% !important;
}

.dashboardpage {
  height: calc(100vh - 104px) !important;
  background-image: url(/public/dashboard_bg.jpg) !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100% !important;
}

.AppRelative {
  padding: 0px !important;
}
.dashboardCards {
  padding: 24px !important;
}
.formPadding {
  padding: 24px !important;
}

/* Truck Loader */

body {
  padding: 0px;
  margin: 0px;
  background-color: #f0f0f0;
}

.loader-wrapper {
  position: fixed;
  z-index: 1090;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
}

.loader-box {
  position: absolute;
  top: 30%;
  left: 40%;
  height: 200px;
  width: 200px;
}

.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #002c5f;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes fade {
  30% {
    opacity: 0.3;
    left: 7px;
  }
  50% {
    opacity: 0.5;
    left: 6px;
  }

  70% {
    opacity: 0.1;
    left: 4px;
  }

  90% {
    opacity: 0.4;
    left: 2px;
  }
}

@keyframes bg {
  from {
    background-position-x: 0px;
  }
  to {
    background-position-x: -400px;
  }
}

@keyframes updown {
  50% {
    transform: translateY(-20%);
  }

  70% {
    transform: translateY(-10%);
  }
}

@keyframes updown-half {
  50% {
    transform: translateY(-10%);
  }

  70% {
    transform: translateY(-5%);
  }
}

.ag-gridResult {
  height: calc(100vh - 170px) !important;
}
.ag-gridReport {
  height: calc(100vh - 480px) !important;
}

.csvUpload {
  height: calc(100vh - 250px) !important;
}

.requiredFieldNull {
  background: white !important;
}
.custom-toast {
  color: red !important; /* Text color */
  background-color: white !important; /* Background color */
  text-transform: capitalize;
  border: 3px solid #002c5f !important;
  width: 600px;
}

.custom-toast .Toastify__progress-bar {
  background: #4caf50 !important; /* Progress bar color */
}

.ag-report-class {
  height: calc(100vh - 390px) !important;
  /* height: 400px !important; */
}

.ag-grid-reports {
  height: calc(100vh - 470px) !important;
}

.ag-grid-fuelEconomy {
  height: calc(100vh - 285px) !important;
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 16px;
  height: 16px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: white;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

.ag-fleetGrid {
  height: calc(100vh - 250px) !important;
}
